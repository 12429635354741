var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "text-center", attrs: { "fill-height": "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "opacity-7",
          attrs: { "align-content": "center", justify: "center" },
        },
        [
          _c(
            "v-col",
            { staticClass: "text-center warning--text", attrs: { cols: "12" } },
            [
              _vm.msg
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.msg) } })
                : _c(
                    "div",
                    [
                      _vm._t("default", function () {
                        return [
                          _vm._v(" Something went wrong, "),
                          _c("br"),
                          _vm._v(" please try again later! "),
                        ]
                      }),
                    ],
                    2
                  ),
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-icon", { attrs: { large: "", color: "warning" } }, [
                _vm._v("mdi-alert-rhombus-outline"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }